import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./services.css";
import S1 from "../../assets/services/Rectangle 1.png";
import S2 from "../../assets/services/Rectangle 2.png";
import S3 from "../../assets/services/Rectangle 3.png";
import S4 from "../../assets/services/Rectangle 4.png";
import S5 from "../../assets/services/Rectangle 5.png";
import S6 from "../../assets/services/Rectangle 6.png";
import S7 from "../../assets/services/bookkeeping.jpg";
import S8 from "../../assets/services/startup.jpg";
import S9 from "../../assets/services/accounting.jpg";
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-reveal";

const Services = () => {
  const datas = [
    {
      title: "MANAGEMENT ADVISORY SERVICES",
      img: S2,
      url: "/management",
    },
    {
      title: "SERVICES PROCESS OUTSOURCING (SPO)",
      img: S1,
      url: "/Outsourcing",
    },
    {
      title: "VIRTUAL CFO SERVICES",
      img: S5,
      url: "/Virtual",
    },
    {
      title: "INTERNATIONAL BOOKKEEPING SERVICES / UAE VAT",
      img: S7,
      url: "/Bookkeeping",
    },
    {
      title: "START UP / COMPANY REGISTRATION and other Related Services ",
      img: S8,
      url: "/Startup",
    },

    {
      title: "AUDITING & ASSURANCE AND CERTIFICATION SERVICES",
      img: S3,
      url: "/Auditing",
    },

    {
      title: "TAXATION AND ZAKAT ADVISORY SERVICES",
      img: S4,
      url: "/Taxation",
    },
    {
      title: "ACCOUNTING AND BOOKKEEPING",
      img: S9,
      url: "/Accounting",
    },

    {
      title: "Other Services ",
      img: S6,
      url: "/Other",
    },
  ];
  return (
    <div className="servMain mt-5 container" id="services">
      <h1 className="text-center">OUR SERVICES</h1>
      <hr />
      <Row>
        {datas.map((p) => (
          <Col md={4} lg={4} xs={12}>
            <center>
              <Link to={p.url} style={{ textDecoration: "none" }}>
                <Zoom>
                  <div className="scard1">
                    <img src={p.img} className="c-img" />

                    <h6 className="mt-3 m-2 c-title">{p.title}</h6>
                  </div>
                </Zoom>
              </Link>
            </center>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Services;
