import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyAImRMcsjQpqZ32fC_Vy39keQh0pAfQNww",
  authDomain: "marsooq-98965.firebaseapp.com",
  projectId: "marsooq-98965",
  storageBucket: "marsooq-98965.appspot.com",
  messagingSenderId: "507328998759",
  appId: "1:507328998759:web:a6ab33a3190d5e9fb853c2",
  measurementId: "G-84Q0D34BDG",
};
firebase.initializeApp(config);

export default firebase;
