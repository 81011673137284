import React from "react";
import "./footer.css";
import FB from "../../assets/fb.png";
import LN from "../../assets/linked.png";
import IG from "../../assets/insta.png";
import WP from "../../assets/whp.png";
import Phone from "../../assets/phone.png";
import Pin from "../../assets/pin.png";
import Mail from "../../assets/mail.png";
import { Nav, Row, Col, Container } from "react-bootstrap";

function Footer() {
  return (
    <div className="foot footbar pt-3 mt-3">
      <Row className=" p-3">
        <Col md={2} lg={2} xs={12}>
          <center>
            <Row>
              <Col>
                <a
                  className="btn"
                  href="https://www.facebook.com/106114788851023/posts/pfbid02u9tNmuWuy9R14Xu9UNtHgbhNAcosFZgWLbvYvaK3Z4XKBUZMJHReqE7A1ng9bPydl/?d=n"
                >
                  <img src={FB} className="ficn" />
                </a>
              </Col>
              <Col>
                <a
                  href="https://www.linkedin.com/company/marsooq-co/"
                  className="btn"
                >
                  <img src={LN} className="ficn" />
                </a>
              </Col>
              <Col>
                <a href="https://www.instagram.com/marsooqnco" className="btn">
                  <img src={IG} className="ficn" />
                </a>
              </Col>
            </Row>
          </center>
        </Col>
        <Col md={8} lg={8} xs={12}>
          <center>
            <div>
              <img src={Pin} className="f-logo" />
              <p className="mt-1 text-center" style={{ color: "#fff" }}>
                <br />
                Access Mall,Near Town hall,
                <br /> Tirur, Malappuram,
                <br />
                Kerala – 676101
                <br /> TIRUR
              </p>
            </div>
          </center>
        </Col>
        <Col md={2} lg={2} xs={12}>
          <center>
            <a className="btn" href="mailto:camarsooq@gmail.com">
              <img src={Mail} className="ficn2" />
            </a>

            <p className="white-color mt-3">camarsooq@gmail.com</p>
          </center>
        </Col>
      </Row>
      <div className="footbar">
        <center>
          <p className="fttext"> Copyright ©2022 All rights reserved</p>
        </center>
      </div>
    </div>
  );
}

export default Footer;
