import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CEOImg from "../../assets/ceo.jpg";
import cartoon from "../../assets/cartoon-contact.png";
import ContactUs from "../ContactUs";
import "./ceo.css";
const CEO = () => {
  return (
    <div className="container">
      <div className="ceocard">
        <Row>
          <Col md={12} lg={6} xs={12} className="fCol">
            <div className="ceo p-5">
              <div className="al-left">
                <h2>Founding Principles</h2>
                <div className="line mb-4 mt-3" />

                <p className="justify">
                  Founded by CA MARSOOQ PM, Qualified Indian Chartered
                  Accountant who have been a part of the industry for years, who
                  continually focussed on client's needs and their requirements
                  to build an everlasting client relationships and being
                  creative in finding the right solutions by maintaining high
                  levels of professionalism and integirity with timely delivery
                  of services.
                </p>
              </div>
              <Row>
                <Col></Col>
                <Col md={4} lg={4} xs={10}>
                  <center>
                    <img src={CEOImg} className="w-100 mt-2 mb-3" />
                  </center>
                </Col>
                <Col></Col>
              </Row>
              <span className="bgblue">
                <h4>CA MARSOOQ PM</h4>
                <i>FOUNDER</i>
              </span>
            </div>
          </Col>
          <Col md={12} lg={6} xs={12}>
            <center>
              <div className="cartoonCon">
                <img src={cartoon} className="cartoon mt-5 w-75" />

                <ContactUs txtbn={true} />
              </div>
            </center>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CEO;
