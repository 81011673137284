import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FixedBar from "../../components/FixedBar";
import HomeBn from "../../components/HomeBn";
import "../../services.css";
import Img from "../../assets/services/vfo.png";
import Footer from "../../components/Footer";
import Fade from "react-reveal/Fade";
import ContactUs from "../../components/ContactUs";
import WhatsApp from "../../components/Whatsapp";

const Virtual = () => {
  return (
    <div className="p-2 serv">
      <FixedBar home={true} />
      <Fade>
        <img src={Img} className="w-100 rounded mb-4 svimg" />
      </Fade>
      <Container>
        <Row>
          <h2 className="svhead">VIRTUAL CFO SERVICES</h2>
        </Row>
        <div className="sline" />
        <p className="justify">
          An entrepreneur managing a growing business needs a strategic partner
          to lead & manage the financial aspects of the business. Our CFO may
          not be present on-site all the time but will always be there when you
          need him/her. As your partner, we don’t just play an advisory role but
          take ownership of the accounting and finance functions including ERP
          implementation, legacy accounting, and audit support. Our experience
          in F&A function has let us build a robust ecosystem with best industry
          practices. Our partner-led approach and in-depth domain knowledge
          benefits our clients in reducing the risks associated with F&A
          function. Depending on the size and the development stage of your
          company, we provide a flexible engagement model, enabling you to
          increase / decrease engagement levels, thereby giving you the
          opportunity of having the best CFO talent to assist you, based on your
          needs. Our services will help you keep your CFO costs low and minimize
          the risk of non-compliances, unavailability of information, lack of
          internal skill set, attrition of key employees, etc.
          <br />
        </p>
        <ul>
          <li>
            <b>Retainer CFO team:</b> We become your partner focusing on
            financial governance, process improvement, MIS & analytics.
          </li>
          <li>
            <b>Insightful MIS:</b> MIS is a backbone of decision making and we
            design a reliable, comprehensive, comparable, timely & insightful
            MIS.
          </li>
          <li>
            <b>Leverage drivers of income and costs:</b> Thorough understanding
            of costs and prices along with the competition.
          </li>
          <li>
            <b>Understanding monetary management:</b> Eliminate bottlenecks in
            flow of money and ensure optimal cycle time.
          </li>
          <li>
            <b> Compliance:</b> Ensure every law of the land is complied with
            the business requirements.
          </li>
        </ul>
        <br />
        <b>
          <u>
            Management support for Internal Financial Controls testing and
            development of standard operating procedures
          </u>
        </b>
        <br />
        <br />
        <p className="justify">
          Internal financial controls include policies and procedures adopted by
          the company for ensuring the orderly and efficient conduct of its
          business, including regulatory compliance and prevention and detection
          of frauds and errors, thereby covering not only the controls over
          reliable reporting of financial statements (more commonly known as
          Internal Financial Controls over Financial Reporting (“IFCFR”), but
          also include all other controls pervasive across the business. The
          Companies Act, 2013 (the “2013 Act”) has imposed specific
          responsibilities on the Board of Directors (“Board”) towards the
          company’s internal financial controls and, inter alia, requires the
          Board to state that they have laid down internal financial controls to
          be followed by the company and that such internal financial controls
          are adequate and were operating effectively.
          <br /> <br />
          By placing more accountability and responsibility on the Board and
          Audit Committee with respect to internal financial controls, the 2013
          Act attempted to align the corporate governance and financial
          reporting standards with global best practices. With adequate and
          effective internal financial controls, some of the benefits that the
          companies are experiencing include:
        </p>
        <ul>
          <li>Senior Management Accountability.</li>
          <li>Improved controls over financial reporting process.</li>
          <li>
            Improved investor confidence in entity’s operations and financial
            reporting process.
          </li>
          <li>
            Promotes culture of openness and transparency within the entity.
          </li>
          <li>Trickling down of accountability to operational management.</li>
          <li>
            Improvements in Board, Audit Committee and senior management
            engagement in financial reporting and financial controls.
          </li>
          <li>More accurate, reliable financial statements.</li>
          <li>Making audits more comprehensive.</li>
        </ul>
        <br />
        <h5>FAQs</h5>
        <br />
        <b>What does a virtual CFO do?</b>
        <br />
        <br />
        <p className="justify">
          A virtual CFO is simply a part-time Chief Financial Officer who
          manages the finance and accounting function of a business virtually.
          The tasks include but are not limited to bookkeeping and day-to-day
          work, financial review and reporting, strategic planning and
          forecasting, and cash management.
        </p>
        <b>What services do you offer as a virtual CFO?</b>
        <br />
        <br />
        <p className="justify">
          We offer you following services as a virtual CFO:
        </p>
        <ul>
          <li>
            We perform all the duties that a traditional CFO does but we work on
            part-time basis.
          </li>
          <li>
            With the help of cloud technology, we monitor your financial health
            and the well-being of your business.
          </li>
          <li>
            We provide you financial insights and guidance on all company
            related decisions and issues.
          </li>

          <li>
            We also offer you back-office services such as manage your accounts,
            ledgers etc.
          </li>
        </ul>
        <b>How long will the process of Virtual CFO take?</b>
        <br />
        <br />
        <p className="justify">
          The time required to complete the process can be as long as necessary
          depending upon the requirement of the client.
        </p>
        <b>
          Does “virtual” mean that Virtual CFOs are only available online or
          over the phone? What if I need someone onsite?
        </b>
        <br />
        <br />
        <p className="justify">
          The “virtual” in Virtual CFOs actually refers to the fact that we are
          not full-time employees. Otherwise we are very real. We work onsite or
          remotely, depending on where we can be most effective.
        </p>
        <b>
          Do I still need a virtual CFO if I already have a controller in my
          company?
        </b>
        <br />
        <br />
        <p className="justify">
          A virtual CFO can do all those things which a controller cannot.
        </p>
        <ul>
          <li>
            A virtual CFO can give you on-time, accurate, and insightful
            financial and operational information about your business.
          </li>
          <li>
            He helps you create and upgrade your strategic plans and assess your
            organizational efficiency.
          </li>
          <li>A virtual CFO helps you take your company to next level.</li>

          <li>
            A virtual CFO assembles a skilled, professional and talented
            accounting staff.
          </li>
          <li>
            And most importantly, a virtual CFO puts together a financial
            package, effectively present it to your banker and get the financing
            you need.
          </li>
        </ul>
        <b>What services we offer on virtual CFO?</b>
        <br />
        <br />
        <p className="justify">
          At Marsooq & Co, our services will help you keep your CFO costs low
          and minimize the risk of non-compliances, unavailability of
          information, lack of internal skill set, attrition of key employees,
          etc. We mold our services keeping into consideration our client’s
          convenience. Depending upon where it can be most effective, we can
          offer you an onsite work as well. So, depending upon the efficacy of
          the terms of engagement, we work both remotely and onsite. Our focus
          is assisting companies with the operational and reporting side of
          accounting on a monthly or weekly basis, as opposed to an annual
          basis. We keep a working relationship with our clients and act as
          their virtual finance team so that all aspects of their accounting
          cycle and reporting are taken care of each month.
        </p>
      </Container>
      <ContactUs />
      <WhatsApp />
      <Footer />
    </div>
  );
};

export default Virtual;
