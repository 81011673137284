import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FixedBar from "../../components/FixedBar";
import HomeBn from "../../components/HomeBn";
import "../../services.css";
import Img from "../../assets/services/register.jpg";
import Footer from "../../components/Footer";
import Fade from "react-reveal/Fade";
import ContactUs from "../../components/ContactUs";
import WhatsApp from "../../components/Whatsapp";

const Others = () => {
  return (
    <div className="p-2 serv">
      <FixedBar home={true} />
      <Fade>
        <img src={Img} className="w-100 rounded mb-4 svimg" />
      </Fade>
      <Container>
        <Row>
          <h2 className="svhead">Other Services </h2>
        </Row>

        <div className="sline" />
        <ol>
          <li>Budgets and Forecast</li>
          <p className="justify">
            Budgeting and financial forecasting are financial planning
            techniques that help business personnel in the decision-making
            process. A budget estimates the amount of revenues and expenses a
            company may incur over a future period. Budgeting represents a
            business’ financial position, cash flows, and goals.
          </p>
          <p className="justify">
            Budgeting is the process of creating a plan to spend your money.
            This spending plan is called a budget. Creating this spending plan
            allows you to determine in advance whether you will have enough
            money to do the things you need to do or would like to do. Budgeting
            is simply balancing your expenses with your income.
          </p>
          <p className="justify">
            Forecasting is another financial tool commonly used to help
            determine the financial status of a company. The meaning of
            financial forecasting is quite different from that of budgeting.
            Where the budget is used as a financial planner, the forecast uses
            this plan and compares it to the current financial direction of the
            company. They do this to predict where the company will end up by
            the end of that year. In other words, the forecast is used to see if
            the company will meet or exceed the expectations from the budget
            allowing the managers and controllers to set future goals. They also
            use forecasts to identify trends that are used to grade the
            company’s financial position.
          </p>
          <br />
          <b>
            An analysis and evaluation of a proposed project to determine if it
          </b>
          <ul>
            <li>is technically feasible</li>
            <li>is feasible within the estimated cost, and</li>
            <li>
              will be profitable.  Feasibility studies are almost always
              conducted where large sums are at stake
            </li>
          </ul>
          <li>Project Reports and Feasibility Studies.</li>

          <p className="justify">
            A Feasibility Study Report (FSR) is a formally documented output
            of feasibility study that summarizes results of the analysis and
            evaluations conducted to review the proposed solution and
            investigate project alternatives for the purpose of identifying if
            the project is really feasible, cost-effective and profitable.
          </p>
          <p className="justify">
            An analysis and evaluation of a proposed project to determine if it
          </p>
          <br />

          <ul>
            <li>is technically feasible</li>
            <li>is feasible within the estimated cost, and</li>
            <li>
              will be profitable.  Feasibility studies are almost always
              conducted where large sums are at stak
            </li>
          </ul>
        </ol>
        <p className="blue-color bold">
          our expert will help you with Get a free consultation today
        </p>
      </Container>
      <ContactUs />
      <WhatsApp />
      <Footer />
    </div>
  );
};

export default Others;
