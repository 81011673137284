import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
  import HomeIcon from "../../assets/home.png";
import "./hm.css";

const HomeBn = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <button className="btn hmbn0" onClick={() => navigate(-1)}>
        <img src={HomeIcon} width={30} />
      </button>
    </div>
  );
};

export default HomeBn;
