import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FixedBar from "../../components/FixedBar";
import HomeBn from "../../components/HomeBn";
import "../../services.css";
import Img from "../../assets/services/bookkeeping.jpg";
import Footer from "../../components/Footer";
import Fade from "react-reveal/Fade";
import WhatsApp from "../../components/Whatsapp";
import ContactUs from "../../components/ContactUs";

const BookKeeping = () => {
  return (
    <div className="p-2 serv">
      <FixedBar home={true} />
      <Fade>
        <img src={Img} className="w-100 rounded mb-4 svimg" />
      </Fade>
      <Container>
        <Row>
          <h2 className="svhead">
            INTERNATIONAL BOOKKEEPING SERVICES / UAE VAT
          </h2>
        </Row>
        <div className="sline" />
        <p>
          we provide offshore Online Bookkeeping Services in UAE, Saudi Arabia,
          Qatar other GCC countries for businesses operating across the world,
          including clients from USA, UK, Australia and Canada. We have the
          resources to work on the accounting platform of your choice. Over time
          our team has worked on different accounting system.
          <br />
          Our skilled consultants act as your virtual accounting team, giving
          you the flexibility of not hiring additional full-time staff; thereby,
          reducing fixed monthly costs. Part of our offering is to allow you to
          scale your virtual accounting team when required. So, during your peak
          business season – you can hire more seats/staff with us, while during
          off-peak times, you only need to pay for the minimum number of seats
          required to manage routine operations. Our team includes a mix of
          experienced bookkeepers and accountants. The work done by bookkeepers
          is regularly reviewed by professional experts to ensure that desired
          standards are maintained. We can also assist you with your company’s
          international tax filings. We offer leading-edge tax consultancy
          services, delivering tangible benefits to our clients. We understand
          national and international tax principles and local and global
          business customs.
        </p>
        <p>
          Our tax professionals are experienced at handling all of the specific
          business and tax issues you currently face, as well as future
          challenges. Our expert consultants will help you comply fully with all
          your company tax requirements. Finally, our tax experts analyse and
          remediate the impact of your company reporting obligations on your
          global tax footprint.
        </p>
        <h5>FAQs </h5>
        <br /> <b>What do you understand by international bookkeeping?</b>
        <br />
        <br />
        <p className="justify">
          International accounting or bookkeeping is a specialty within the
          entire discipline that is focused on using accounting standards that
          are as relevant when balancing the books of accounts of a company
          overseas.
        </p>
        <br />
        <b>
          What services do we offer under International bookkeeping and
          accounting?
        </b>{" "}
        <br />
        <br />
        <p className="justify">
          Bookkeeping is an essential component of any occupation, a small,
          medium, or large business. At Marsooq & Co, we offer:
          <br />
        </p>
        <ul>
          <li>Initial setup</li>
          <li>Recording of financial statements</li>
          <li>Accounts payable or accounts receivable management</li>
          <li>Preparation of financials</li>
          <li>Inventory management</li>
          <li>Job costing</li>
          <li>Transition services</li>
        </ul>
        <b>
          What is financial and analytical support, and what services do you
          offer under financial and analytical support?
        </b>{" "}
        <br />
        <br />
        <p className="justify">
          Financial and analytical support includes integrating and analyzing
          information saved in financial systems and other critical information
          sources across the organization. At Marsooq & Co, we use methods such
          as statistical analysis to define the organization’s purpose,
          performance, and accountability information. In other words, we use
          data analytics to tell the organization’s story and the areas that can
          be refined. We look at features like:
          <br />
        </p>
        <ul>
          <li>
            The assessment of whether the entity’s current assets and
            investments are the best use of the entity’s excess working capital.
          </li>
          <li>
            The ratio analysis calculates ratios such as gross turnover, total
            cost, gross margin, net profit, the rate of expenses versus
            turnover, and the income versus gross income ratio.
          </li>
          <li>
            Regulating which products or product lines generate the most
            significant portion of the organization’s net earnings.
          </li>
          <li>
            The devising of internal reports for executive leadership to support
            their decision making.
          </li>
          <li>
            Break-even analysis to determine what you need to sell, monthly or
            annually, to cover your costs of doing business, in other words,
            your break-even point.
          </li>
          <li>
            Variance analysis to determine overall over-or under-performance for
            a particular reporting period. For each item, we assess its
            favorability by comparing actual costs versus standard costs.
          </li>
          <li>
            Measuring KPIs (key performance indicators) to exhibit how
            successfully the business achieves its key business objectives.
          </li>
        </ul>
        <b>
          Why is scanning and folder management necessary in an organization?
        </b>{" "}
        <br />
        <br />
        <p className="justify">
          To help businesses save time and improve workflow efficiency,
          installing a file management system is advisable. At Marsooq & Co, we
          assist with selecting and implementing a file management system to
          ensure that your business’s workflow and output efficiencies increase
          substantially. We can assist in the following activities:
          <br />
        </p>
        <ul>
          <li>Creating customer and category folders</li>
          <li>
            Renaming the scanned files using industry standards and
            best-practice naming conventions
          </li>
          <li>
            Managing all business documents such as scans, bills, invoices in
            appropriate folders
          </li>
          <li>Maintaining the electronic document repository</li>
        </ul>
        <b>
          Why planning, budgeting & forecasting is necessary for an
          organization?
        </b>
        <br />
        <br />
        <p className="justify">
          Profit is the primary measure of a company’s success. Usually, company
          profit does not just happen. It has to be managed. Therefore, the
          firm’s profitability entirely depends on the extent to which company
          management follows proper planning, effective coordination, and
          dynamic control protocols. This requires that management budget or
          plan for the future, including financial and physical requirements, is
          essential to maintaining company productivity and profitability. When
          companies embrace data and analytics in conjunction with
          well-established planning and forecasting best practices, they enhance
          strategic decision-making and be rewarded with more accurate plans and
          more timely forecasts. Overall, these tools and techniques can save
          time, reduce errors, promote collaboration and foster a more
          disciplined management culture that delivers a true competitive
          advantage.
          <br />
        </p>
        <b>
          What services do we offer under planning, budgeting & forecasting?
        </b>
        <br />
        <br />
        <p className="justify">
          At Marsooq & Co, we assist you with the following services:
          <br />
        </p>
        <ul>
          <li>Preparing sales projections</li>
          <li>Preparing cash flow projections</li>
          <li>Creation of financial/administrative budgets</li>
          <li>Creation of sales and marketing budgets</li>
          <li>Preparation of projected financials</li>
          <li>Conceptualizing profit first as a business approach</li>
          <li>
            Developing and applying strategic plans and cost-saving techniques
          </li>
          <li>Executing rolling forecasts and budgets</li>
        </ul>
      </Container>
      <ContactUs />
      <WhatsApp />
      <Footer />
    </div>
  );
};

export default BookKeeping;
