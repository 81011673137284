import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./team.css";
import Icon1 from "../../assets/prof.png";
import Icon2 from "../../assets/acc.png";
import Icon3 from "../../assets/integ.png";
import Icon4 from "../../assets/trans.png";
import Icon5 from "../../assets/conf.png";
import Vision from "../Vision";
import CEO from "../CEO";

const ChooseUs = () => {
  const [cstate, setState] = useState(false);
  return (
    <div className="teamMain mt-5 container-fluid">
      <Container fluid>
        <h3 className="text-center">
          <b>Why Marsooq & Co ?</b>
        </h3>
        <Row>
          <Col md={1} lg={1} xs={0}></Col>
          <Col>
            <p className="text-center italic w-100">
              At Marsooq & Co. we provide quality professional services in an
              independent, objective, and ethical manner by bringing a new
              insight and fresh perspective to every issue.
            </p>
          </Col>
          <Col md={1} lg={1} xs={0}></Col>
        </Row>
        <Row>
          <Col md={1} lg={1} xs={0}></Col>
          <Col md={2} lg={2} xs={6}>
            <center>
              <img src={Icon1} className="cimg w-100" />
            </center>
          </Col>
          <Col md={2} lg={2} xs={6}>
            <center>
              <img src={Icon2} className="cimg w-100" />
            </center>
          </Col>
          <Col md={2} lg={2} xs={6}>
            <center>
              <img src={Icon3} className="cimg w-100" />
            </center>
          </Col>
          <Col md={2} lg={2} xs={6}>
            <center>
              <img src={Icon4} className="cimg w-100" />
            </center>
          </Col>
          <Col md={2} lg={2} xs={6}>
            <center>
              <img src={Icon5} className="cimg w-100" />
            </center>
          </Col>
          <Col md={1} lg={1} xs={3}></Col>
        </Row>
        <Row>
          <Col md={1} lg={1} xs={0}></Col>
          <Col>
            <div className="subcard w-100">
              <p className="justify-center subp mt-3">
                We maintain at all times the highest professional standards of
                independence, integrity, ethics and objectivity and promote
                excellence as a way of life and culture of our firm, which
                reflects in all we do.
              </p>
              <p className="justify-center subp">
                The work culture is transperent, efficient, and schedule
                oriented with intelligents and devoted professionals to impart
                the optimal blend of expertise to every assignment with the
                objective of developing practical and tailor-made solutions for
                our clients.
              </p>
              <p className="justify-center subp">
                Developing innovative and out of the box solutions which result
                in value enhancement for our clients. And recognise, respect and
                honestly discharge our responsibility to our clients, our people
                and the society at large.
              </p>
            </div>
          </Col>
          <Col md={1} lg={1} xs={0}></Col>
        </Row>
      </Container>
      <center>
        {cstate == false ? (
          <button
            className="btn btn-outline-secondary mt-3"
            onClick={() => setState(!cstate)}
          >
            View more
          </button>
        ) : (
          <div className="container">
            <Vision />

            <CEO />
            <button
              className="btn btn-outline-secondary mt-3"
              onClick={() => setState(!cstate)}
            >
              View less
            </button>
          </div>
        )}
      </center>
    </div>
  );
};

export default ChooseUs;
