import React from "react";
import { Nav, Row, Col, Container, Navbar } from "react-bootstrap";
import "./fixed.css";
import Logo from "../../assets/logo-new.png";
import HomeBn from "../HomeBn";
import Fade from "react-reveal/Fade";

const FixedBar = (props) => {
  return (
    <div className="fixed-top p-3">
      <Row>
        <Col md={3} lg={3} xs={7}>
          <Navbar.Brand href="/">
            <img src={Logo} className="blogo" />
          </Navbar.Brand>
        </Col>
        <Col ></Col>
        {props.home == true ? null : (
          <Col xs={4}>
            <center>
              <a href="/#services" className="p-2 nav-link slink">
                <span className="blue-color ml-1 bold">Services </span>
              </a>
            </center>
          </Col>
        )}
        {props.home == true ? (
          <Col lg={1} xs={4}>
            <Fade>
              <HomeBn />
            </Fade>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default FixedBar;
