import React from "react";
import { Col, Row } from "react-bootstrap";

import banner from "../../assets/banner.png";
import VisionLogo from "../../assets/vision.png";
import MissionLogo from "../../assets/mission.png";
import Curve from "../../assets/curve.png";
import "./sec2.css";

const Vision = () => {
  return (
    <div className="container vscon mt-1 mb-5 vision">
      <Row className="mb-3">
        <Col md={6} lg={6} xs={12}>
          <div className="vscard mt-1">
            <h4 className=" vhd mt-5">OUR VISION</h4>
            <p className="justify">
              To be a highly reputed and trusted professionals consistently
              strive to improve our quality of services and trying to be one of
              the best in the region & emerging markets. Breaking all
              conventional relationship formula between a consultant and client
              by becoming ourselves a part of the business while providing best
              working environment where our firm members evolve professionally
              and can be responsible members of our community and also
              contributing positively to Corporate social responsibilities.
            </p>
          </div>
        </Col>

        <Col md={6} lg={6} xs={12}>
          <div className="vscard mt-1">
            <h4 className=" vhd mt-5">OUR MISSION</h4>
            <p className="justify">
              Exceeding the expectation of our clients by consistently rendering
              quality services and ensure the financial security of our clients
              to enhance the confidence level by providing our clients a stand
              out position by being innovative, proactive and true to our core
              values while providing the highest possible quality in everything
              we do. Our endeavor is to provide qualitative and expert
              professional services rendered efficaciously, sagaciously and with
              keen attention to details. We recognize the need to provide
              timely, focused and issue-based advice to our clients. Our
              dedicated professional team has carved a discernible niche for
              itself in the professional services industry.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Vision;
