import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FixedBar from "../../components/FixedBar";
import HomeBn from "../../components/HomeBn";
import "../../services.css";
import Img from "../../assets/services/accounting.jpg";
import Footer from "../../components/Footer";
import Fade from "react-reveal/Fade";
import ContactUs from "../../components/ContactUs";
import WhatsApp from "../../components/Whatsapp";

const Accounting = () => {
  return (
    <div className="p-2 serv">
      <FixedBar home={true} />
      <Fade>
        <img src={Img} className="w-100 rounded mb-4 svimg" />
      </Fade>
      <Container>
        <Row>
          <h2 className="svhead">ACCOUNTING AND BOOKKEEPING</h2>
        </Row>

        <div className="sline" />
        <ol>
          <p className="justify">
            Accounting and bookkeeping is an integral part of business to make
            strategic decision for any company, be it a startup or an
            established company. Accounting and bookkeeping services is stepping
            stones to an organization’s success.
          </p>
          <p className="justify">
            We provide timely financial information to our clients in a timely
            and accurate manner is a commitment that we feel cannot be
            compromised. Meaningful, well-organized financial records ensure
            that your business operations will run more efficiently on a daily
            basis. Our bookkeeping and accounting services provide a flexible
            and cost-effective solution that can be shaped to meet your needs.
            Our experts look after all your compliance requirements – including
            VAT, payroll and statutory or legislative compliance - leaving you
            to concentrate on growing your business. Our firm provides a full
            range of cost effective accounting services
          </p>
          <li>BACKLOG ACCOUNTING SERVICES</li>
          <p className="justify">
            Companies that have been in operation for few years, especially
            SME’s would not have maintained their books of accounts or they
            would have been maintaining their transactions either in old school
            way of recording in books or excel sheets. Business once grow find
            it difficult to set apart time for recording the old transaction in
            an orderly manner.
          </p>
          <p className="justify">
            We here pave the way to perceive the future of the business to
            defines the financial position by updating Backlog accounts ie,
            recording all the prior period transactions from the inception of
            the business and ensuring all the transactions are captured to
            portray the true positions of the company, contact our expertise for
            the services.
          </p>
          <li>REGULAR ACCOUNTING SERVICES / BOOKKEEPING </li>
          <p className="justify">
            Recording the transactions of a company is an integral part that
            contributes to the growth of the company. Accounting helps a
            business to track its income and expenses, thus reflecting the
            result of the operations. The daily transactions of a company need
            to be recorded and accounted for to consider prospects for the
            business
          </p>
          <p className="justify">
            Accounting and Financial reporting will helps the investors to
            understand the financial position of a company, analyze the
            investment opportunities, and reduces expenses that are not vital to
            the business, the prospectus of the business can be determined only
            if the accounts are maintained. Accounts Outsourcing services is
            most suitable option for an investor wherein she/he can dedicate
            their complete time towards the operations and at the same time
            ensure that the records are maintained, thus being able to reap
            maximum benefits. What we can provide:
          </p>
          <p>
            The daily transactions of the company are recorded in the accounting
            software on a periodic basis and reports are generated on a periodic
            basis, followed by financial analyses with the management, and our
            associates will visit your premises on a periodic basis (weekly or
            monthly)
          </p>
          <li>FINALIZATION OF ACCOUNTS</li>
          <p className="justify">
            The name defines it as it could be a type of accounting where a team
            of accountants prepare the final accounts after making all the
            adjustments. Such process of closing accounts may be called
            finalization accounting and normally it is proceed at financial year
            end of a company.It contains all the reports as per requirements of
            the concerned departments. Normally it includes:
          </p>
          <ul>
            <li>Balance sheet</li>
            <li>Profit and loss account</li>
            <li>Statement of changes in equity</li>
            <li>Cash flow statement</li>
            <li>Notes to the accounts.</li>
            <li>Directors reports</li>
            <li>Any other reports (depending on the requirements)</li>
          </ul>
          <p>
            All these reports help to create a picture about the current
            financial position of a business and its future.
          </p>
          <li>MANAGEMENT ACCOUNTING</li>
          <p className="justify">
            Management accounts are critical to the success of a business. These
            can be prepared monthly, quarterly or half yearly depending on your
            specific business needs. We could offer monthly or quarterly
            management accounts which will include the following:
          </p>
          <ul>
            <li>Annual budgets and forecasts</li>
            <li>
              Monthly profit & loss accounts, with reports on variances from
              budgets
            </li>
            <li>Cash flow forecasts</li>
            <li>
              Analytical reports with regard to purchases, sales, stocks,
              employee costs, overhead expenses
            </li>
            <li>Business trends and key performance indicators</li>
            <li>Advice on key value drivers</li>
          </ul>
          <br />
          <p className="justify">
            We can tailor these reports to fit in to your specific needs. If
            your business operates from more than one sales outlet or if it
            sells more than one product or provides more than one type of
            service separate reports could be provided.
          </p>
        </ol>
        <p className="justify blue-color">
          Our expert will help you with Get a free consultation today
        </p>
      </Container>
      <ContactUs />
      <WhatsApp />
      <Footer />
    </div>
  );
};

export default Accounting;
