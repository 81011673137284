import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import ScrollToTop from "../components/ScrollToTop";
import Auditing from "./Auditing";
import Management from "./Management";
import Outsourcing from "./Outsourcing";
import Virtual from "./Virtual";
import BookKeeping from "./Bookkeeping";
import Startup from "./Startup";
import Others from "./Others";
import Accounting from "./Accounting";
import Taxation from "./Taxation";
import Login from "../Admin/Login";
import AddBlogs from "../Admin/AddBlogs";
import Blog from "./Blog";

const Entrypoint = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/Auditing" element={<Auditing />} />
        <Route exact path="/Management" element={<Management />} />
        <Route exact path="/Outsourcing" element={<Outsourcing />} />
        <Route exact path="/Virtual" element={<Virtual />} />
        <Route exact path="/bookkeeping" element={<BookKeeping />} />
        <Route exact path="/Startup" element={<Startup />} />
        <Route exact path="/Other" element={<Others />} />
        <Route exact path="/Accounting" element={<Accounting />} />
        <Route exact path="/Taxation" element={<Taxation />} />
        <Route exact path="/Admin_login" element={<Login />} />
        <Route exact path="/Add_blogs" element={<AddBlogs />} />
        <Route exact path="/blog/:id" element={<Blog />} />
      </Routes>
    </Router>
  );
};
export default Entrypoint;
