import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import firebase from "../../firebase";
import "./blogs.css";
import { BrowserView, MobileView } from "react-device-detect";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  //   const items = [{ content: "check1" }, { content: "check2" }];
  useEffect(() => {
    fetchDatas();
  }, []);

  const fetchDatas = async () => {
    try {
      var datas = [];
      var snapshot = await firebase.firestore().collection("blogs").get();
      snapshot.forEach((doc) => {
        datas.push(doc.data());
      });
      setItems([...datas]);
    } catch (err) {
      console.log(err);
    }
  };

  function appendDots(dots) {
    return (
      <div style={{ backgroundColor: "#eee" }}>
        <ul style={{ margin: "3px" }}> {dots} </ul>
      </div>
    );
  }

  return (
    <div className="container-fluid slideCon mt-5">
      <h3 className="otxt text-center mb-5">-----Featured insights------</h3>
      <Row>
        {items.length<=2?
        <BrowserView>
          <Slider
            dots={false}
            slidesToShow={items.length}
            autoplay={true}
            autoplaySpeed={2500}
          >
            {items.map((p) => (
              <Col md={4} lg={4} xs={12}>
                <div className="card-blog">
                  <center>
                    <img
                      src={p.url}
                      className="imagecom w-100"
                      height={200}
                      style={{ objectFit: "contain" }}
                    />
                    <p className="text-center">
                      <i className="p-3">{p.head}</i>
                    </p>
                    <button
                      class="button-17"
                      role="button"
                      onClick={() => navigate("/blog/" + p.uid)}
                    >
                      View More
                    </button>
                  </center>
                </div>
              </Col>
            ))}
          </Slider>
          <br />
        </BrowserView>: <BrowserView>
          <Slider
            dots={false}
            slidesToShow={3}
            autoplay={true}
            autoplaySpeed={2500}
          >
            {items.map((p) => (
              <Col md={4} lg={4} xs={12}>
                <div className="card-blog">
                  <center>
                    <img
                      src={p.url}
                      className="imagecom w-100"
                      height={200}
                      style={{ objectFit: "contain" }}
                    />
                    <p className="text-center">
                      <i className="p-3">{p.head}</i>
                    </p>
                    <button
                      class="button-17"
                      role="button"
                      onClick={() => navigate("/blog/" + p.uid)}
                    >
                      View More
                    </button>
                  </center>
                </div>
              </Col>
            ))}
          </Slider>
          <br />
        </BrowserView>}
        <MobileView>
          <Slider
            slidesToShow={1}
            dots={false}
            autoplay={true}
            autoplaySpeed={2500}
          >
            {items.map((p) => (
              <Col md={4} lg={4} xs={12}>
                <div className="card-blog">
                  <center>
                    <img
                      src={p.url}
                      className="imagecom w-100"
                      height={200}
                      style={{ objectFit: "contain" }}
                    />
                    <p className="text-center">
                      <i className="p-3">{p.head}</i>
                    </p>
                    <button
                      class="button-17"
                      role="button"
                      onClick={() => navigate("/blog/" + p.uid)}
                    >
                      View More
                    </button>
                  </center>
                </div>
              </Col>
            ))}
          </Slider>
        </MobileView>
      </Row>
    </div>
  );
};

export default Blogs;
