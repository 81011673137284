import React from "react";
import {
  Container,
  Navbar,
  NavDropdown,
  Nav,
  Col,
  Row,
  Carousel,
} from "react-bootstrap";

import "./nav.css";
import S1 from "../../assets/slider/S1.jpg";
import S2 from "../../assets/slider/S2.jpg";
import S3 from "../../assets/slider/S3.jpg";

import FixedBar from "../FixedBar";
import { useNavigate } from "react-router-dom";

function NavBar() {
  const navigate = useNavigate();

  return (
    <div className="NavCon">
      <FixedBar />
      <Container fluid className="carCon">
        <Carousel className="carousel-main">
          <Carousel.Item>
            <img className="d-block w-100 item " src={S1} />
            <Carousel.Caption>
              <h3 className="blue-color bg-white shadow p-3">
                <b>AUDITING & ASSURANCE AND CERTIFICATION SERVICES</b> <br />
                <button
                  className="btn btn-outline-primary mt-3 "
                  onClick={() => navigate("/Auditing")}
                >
                  Read More
                </button>
              </h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100 item" src={S2} />
            <Carousel.Caption>
              <h3 className="blue-color bg-white shadow p-3">
                <b>SERVICES PROCESS OUTSOURCING (SPO)</b>
                <br />
                <button
                  className="btn btn-outline-primary mt-3 "
                  onClick={() => navigate("/Outsourcing")}
                >
                  Read More
                </button>
              </h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100 item" src={S3} />
            <Carousel.Caption>
              <h3 className="blue-color bg-white shadow p-3">
                <b>VIRTUAL CFO SERVICES</b> <br />
                <button
                  className="btn btn-outline-primary mt-3 "
                  onClick={() => navigate("/Virtual")}
                >
                  Read More
                </button>
              </h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Container>
    </div>
  );
}

export default NavBar;
