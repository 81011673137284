import React, {useEffect, useState,useRef} from "react";
import {Button, Col, Row} from "react-bootstrap";
import Check from "../../components/Check";
import Loading from "../../components/Loading";
import Navbars from "../../components/Navbar2";
import firebase from "../../firebase";
import "./arrivals.css";
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';

const AddBlogs = () => {
    const [arrivals, setArrivals] = useState([]);
    const [image, setImage] = useState(null);
    const [transfer, setTransfer] = useState();
    const [loading, setLoading] = useState();
    const [head, setHead] = useState();

    const editor = useRef(null);
    const [content, setContent] = useState('');

    useEffect(() => {
        fetchArrivals();
    }, []);

    const fetchArrivals = async () => {
        try {
            var datas = [];
            var snapshot = await firebase.firestore().collection("blogs").get();
            snapshot.forEach((doc) => {
                datas.push(doc.data());
            });
            setArrivals([... datas]);
        } catch (err) {
            console.log(err);
        }
    };

    const removeProduct = async (uid, url, cat) => {
        if (window.confirm("Delete the item?")) {
            try {
                setLoading(true);
                let imageRef = firebase.storage().refFromURL(url);
                let task2 = imageRef.delete();
                await task2;
                task2.then(function () {
                    firebase.firestore().collection("blogs").doc(uid).delete().then((res) => {
                        alert("Post removed successfully !");
                        window.location.reload();
                    });
                });
            } catch (err) {
                console.log(err);
            }
        }
    };

    const upload = async (cat) => {
        if (content != "" && image != null) {
            let date = new Date();
            date = date.toString();
            let name = image.name + date;

            setLoading(true);
            if (image == null) 
                return;
            
            const storageRef = firebase.storage().ref(`/blogs/${name}`);
            const task = storageRef.put(image);

            task.on("state_changed", (taskSnapshot) => {
                setTransfer(Math.round(taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) * 100);
                console.log(transfer);
            });
            try {
                await task;
                task.then(function (snapshot) {
                    storageRef.getDownloadURL().then((url) => {
                        const docRef = firebase.firestore().collection(cat).doc();
                        const docId = docRef.id;

                        docRef.set({uid: docId, url: url, content: content, head: head}).then(function () {
                            alert("image uploaded");
                            window.location.reload();
                        });
                    });
                });
            } catch (err) {
                console.log(err);
            }
            setImage("");
        } else {
            alert("Please fill out all fields !");
        }
    };

    return (
        <div>
            <Check/> {
            loading == true ? (
                <Loading/>) : (
                <div>
                    <Navbars active={"link-1"}/>
                    <hr/>
                    <div className="container obn">
                        <center>
                            <div className="tbn">
                                <h2>Blogs</h2>

                                <div className="container obn">
                                    <center>
                                        <div className="tbn">
                                            <input type="file"
                                                onChange={
                                                    (e) => {
                                                        setImage(e.target.files[0]);
                                                    }
                                                }
                                                className="btn-light btn w-100"/>
                                            <input type={"text"}
                                                onChange={
                                                    (e) => setHead(e.target.value)
                                                }
                                                placeholder="enter the title "
                                                className="m-3 w-100"/> {/* <textarea
                                                  type={"text"}
                                                  onChange={(e) => setContent(e.target.value)}
                                                  placeholder="enter the content "
                                                  className="m-3 w-100"
                                                /> */}

                                            <JoditEditor ref={editor}
                                                value={content}
                                                tabIndex={1}
                                                // tabIndex of textarea
                                                onBlur={
                                                    newContent => setContent(newContent)
                                                }
                                                // preferred to use only this option to update the content for performance reasons
                                                onChange={
                                                    newContent => {}
                                                }/>

                                            <button className="btn btn-success w-100 mb-3"
                                                onClick={
                                                    () => upload("blogs")
                                            }>
                                                Upload
                                            </button>
                                        </div>
                                    </center>
                                </div>
                                <Row> {
                                    arrivals.map((p) => (
                                        <Col xs={12}
                                            md={6}
                                            lg={4}>
                                            <div className="card-b">
                                                <img src={
                                                        p.url
                                                    }
                                                    width="100%"
                                                    className="b-img"/>
                                                <h3>{
                                                    p.head
                                                }</h3>
                                                <p className="p-3">
                                                {parse(p.content)}
                                                </p>
                                                <Button className="btn btn-danger mt-1"
                                                    onClick={
                                                        () => removeProduct(p.uid, p.url, "newarrivals")
                                                }>
                                                    X Remove
                                                </Button>
                                            </div>
                                        </Col>
                                    ))
                                } </Row>
                            </div>
                            <hr/>
                        </center>
                    </div>
                </div>
            )
        } </div>
    );
};
export default AddBlogs;
