import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FixedBar from "../../components/FixedBar";
import HomeBn from "../../components/HomeBn";
import "../../services.css";
import Img from "../../assets/services/auditing.jpg";
import Footer from "../../components/Footer";
import Fade from "react-reveal/Fade";
import ContactUs from "../../components/ContactUs";
import WhatsApp from "../../components/Whatsapp";

const Auditing = () => {
  return (
    <div className="p-2 serv">
      <FixedBar home={true} />
      <Fade>
        <img src={Img} className="w-100 rounded mb-4 svimg" />
      </Fade>
      <Container>
        <Row>
          <h2 className="svhead">
            AUDITING & ASSURANCE AND CERTIFICATION SERVICES
          </h2>
        </Row>
        <div className="sline" />

        <p>
          An entrepreneur journey to success is governed by increasingly complex
          and broadening regulatory requirements. We can assist you meet these
          demands by providing a timely and constructive challenge to
          management, a robust and clear perspective to audit committees, and
          transparent information to your stakeholders and investors.
          <br />
          As a firm, we have expert knowledge and extensive experience to help
          with complex reporting issues involving IND-AS, IAS, US-GAAP and IFRS.
        </p>
        <p>WE PROVIDE THE FOLLOWING AUDIT AND ASSURANCE SERVICES:</p>
        <ul>
          <li>
            Statutory Audits, Tax audit, Reviews of Financial statements and GST
            Audit
          </li>
          <li>Internal Audits</li>
          <li>
            Financial Due Diligence, Forensic, Special investigation and Mystery
            audits
          </li>
          <li>Stock and fixed assets audits</li>
          <li>CARO 2020 support,</li>
        </ul>
        <ol>
          <li>
            Statutory Audit, Tax Audit, Review of Financial statements and GST
            Audit
          </li>
          <p className="justify mt-1">
            While many of the rules of business have changed, the fundamentals
            haven’t. Meeting investor expectations begins with the completeness,
            accuracy and fair presentation of information in your financial
            statements and disclosures. The audit is key to sustaining
            confidence in both your company and the financial system at large.
            It must keep pace with a fast-evolving corporate reporting
            environment – one characterised by investor scepticism and the 24/7
            scrutiny of corporate information, including non-financial
            information. Auditing thus involves much more than just the legal
            obligation of certifying annual accounts. Whether annual and
            consolidated accounts or special audit services and certifications,
            having the objective judgment of external auditors makes information
            more reliable for all decision makers.
          </p>
          <p className="justify">
            Our professionals at At Marsooq & Co have many years’ experience,
            competence, industry knowledge and integrity. Our audit approach, is
            tailored to suit the size and nature of any organization and draws
            upon our extensive knowledge and talent, understanding of laws,
            regulations and legislation. We approach your audit with a deep and
            broad understanding of your business, the industry in which you
            operate, and the latest regulatory standards, helping you deliver
            value confidently through transparency of your reporting to
            stakeholders.
          </p>
          <li>Internal Audits</li>
          <p className="justify mt-1">
            Risk management is a big concern for both audit committees and
            senior management in corporate internally. Internal audit is an
            independent management function, which involves a continuous and
            critical appraisal of the functioning of an entity with a view to
            suggest improvements thereto and add value to and strengthen the
            overall governance mechanism of the entity, including the entity’s
            strategic risk management and internal control system.
          </p>
          <p className="justify">
            Internal Audit helps an organization accomplish its objectives by
            bringing a systematic, disciplined approach to evaluate and improve
            the effectiveness of risk management, control, and governance
            processes. With commitment to integrity and accountability, internal
            auditing provides value to senior management as an objective source
            of independent advice.
          </p>
          <li>
            Financial Due Diligence, Forensic, Special investigations and
            Mystery audits
          </li>
          <p className="justify mt-1">
            We provide comprehensive due diligence services involving Business,
            Financial, Tax and Legal diligence (either in-house or through our
            network partners) to identify idiosyncratic business, financial
            risks and opportunities. Effective Due Diligence depends on
            identifying and then managing significant transaction issues,
            anticipating and identifying potentially important risk and
            negotiation issues. It is imperative to have reliable, timely and
            qualitative information in any potential transaction to enable
            informed decision making. The success of the investigation to make a
            well-informed decision would lie in a well-planned, integrated and
            coordinated detailed enquiry procedures.
          </p>
          <p>
            All the documents of the firm are assembled and reviewed, the
            management is interviewed and a team of financial experts analyse
            the information to see if the business is worth buying.
          </p>
          <p>
            In addition, financial due diligence analyses the assets and
            liabilities to be acquired.
          </p>
          <p>
            Due diligence includes a critical review of the target company’s
            financial statements for the past several years and a review of the
            financial projections for the coming years.
          </p>
          <p>Other areas which are analysed are:</p>
          <ul>
            <li>Key contracts.</li>
            <li>Financial statements.</li>
            <li>Employment agreements.</li>
            <li>
              Minutes and consents of the board of directors and shareholders.
            </li>
            <li>
              Patents, copyrights, and other intellectual property-related
              documents.
            </li>
            <li>
              Licenses and permits related to the operation of the business.
            </li>
          </ul>
          <p>
            Further it is precisely in difficult economic times that preventing
            and investigating financial crime such as embezzlement, fraud and
            corruption becomes particularly important. We can assist you to
            manage risk, investigate alleged misconduct and fraud and measure
            the financial implications of disputes.
          </p>
          <p>
            If an instance of unusual financial activity is suspected, our team
            can conduct forensic investigations, audits, and third party due
            diligence by leveraging forensic technology to uncover any
            irregularities, keeping in mind the sensitivity and urgency
            required. We can assist you in the following:
          </p>
          <ul>
            <li>
              Detailed process reviews including review of soft data related to
              accounting and operations to test for any malpractice or
              suppression.
            </li>
            <li>
              Expense verification including reviews to check for overstatement
              or fictitious expenditure used to siphon out money or understate
              revenue.
            </li>
            <li>
              Detailed analysis of physical invoices to test their authenticity
              and correctness.
            </li>
            <li>
              Forensic study of email boxes, to understand email culture and
              check for transfer of information critical to an organization.
            </li>
            <li>Mystery Audits</li>
          </ul>
          <li>Stock and fixed assets audits</li>
          <p>
            Stock and fixed assets Audit is also an area of specialization and
            core competence for At Marsooq & Co. Stocks and physical assets such
            as raw materials are critical real assets and needs systematized
            management. As a large number of companies are operating across the
            borders through multiple locations and channel partners, making
            asset management a challenge. We aim at delivering focused services
            to companies to keep their physical assets check intact.
          </p>
          <li>Other Certifications </li>
          <p>
            At Marsooq & Co, qualified chartered accountants can assist you in
            obtaining certificates and their final submission to the concerned
            authority
          </p>
          <b>NET WORTH CERTIFICATE</b>
          <p>
            Net worth is computed when the total liabilities of an individual or
            enterprise are deducted from the total assets. Condition of the
            cumulative financial worth of an individual or enterprise at a point
            in time is computed by its respective net worth. Net worth is used
            as an indication of financial health of an enterprise in various
            processes. Such a certificate is an arrangement compiled and
            certified by a chartered accountant after taking all assets and
            liabilities into account. It is generally used as a part of visa
            application, bank loan application, franchise application, etc.
          </p>
          <b>TURNOVER CERTIFICATE</b>
          <p>
            Turnover is defined as the sum total of all business activities that
            a company does in a period of time. Comparison between the inflows
            with the outflows of funds is evaluated and whether the entity is
            earning profits or incurring losses is established. Genuinity of
            transactions is taken into account before issuing turnover
            certificate. Participation in tenders is one such situation which
            requires turnover certificate.
          </p>
        </ol>
      </Container>
      <ContactUs />
      <WhatsApp />
      <Footer />
    </div>
  );
};

export default Auditing;
