import React, { useState } from "react";
import About from "../../components/About";
import NavBar from "../../components/NavBar";
import AboutUs from "../../components/About";
import Vision from "../../components/Vision";
import "./home.css";
import Services from "../../components/services";
import Footer from "../../components/Footer";
import ContactUs from "../../components/ContactUs/";
import FixedBar from "../../components/FixedBar";
import ChooseUs from "../../components/ChooseUs";
import News from "../../components/News";
import WhatsApp from "../../components/Whatsapp";
import Blogs from "../../components/Blogs";
const Home = () => {
  return (
    <div>
      <NavBar />

      <AboutUs />

      <ChooseUs />

      <Services />

      <Blogs />

      <ContactUs />

      <WhatsApp />

      <Footer />
    </div>
  );
};

export default Home;
